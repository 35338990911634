<template lang="pug">
  // #app(v-bind:style="{ top: y + 'px', left: x + 'px', width: w + 'px', height: h + 'px' }")
  #app(v-bind:style="{ transform: `scale(${zoom}) translate(-50%,-50%)` }")
    router-view
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      zoom: 1
    }
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', () => this.resize())
    window.addEventListener('orientationchange', () => this.resize())
  },

  methods: {
    resize() {
      const ww = document.documentElement.clientWidth
      const wh = document.documentElement.clientHeight
      const xr = ww / 1920
      const yr = wh / 1080
      this.zoom = Math.min(xr, yr)
    }
  }
}
</script>
<style lang="less">
@font-face {
  font-family: Animeace;
  src: url("/fonts/animeace2_reg.otf") format("opentype");
}
@font-face {
  font-family: Animeace;
  src: url("/fonts/animeace2_bld.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: Animeace;
  src: url("/fonts/animeace2_ital.otf") format("opentype");
  font-style: italic;
}

@font-face {
  font-family: Bionicle;
  src: url("/fonts/Bionicle-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Barlow;
  src: url("/fonts/BarlowSemiCondensed-SemiBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

.w-100 {
  width: 100%;
  height: auto;
}

.p-absolute {
  position: absolute;
  left: 0;
  top: 0;
}
.absolute-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

body {
  background: black;
  overflow: hidden;
  color: white;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.no-pointer-events {
  pointer-events: none;
}

.bg {
  z-index: -1;
}

.text-block, button {
  border: 5px solid black;
  box-sizing: border-box;
  background: white;
  padding: 0.5em 0.8em;
  color: black;
  line-height: 1.5em;
  font-size: 30px;
  font-family: Animeace;
}
.text-top {
  position: absolute;
  top: 2em;
}
.text-top-2 {
  position: absolute;
  top: 4em;
}
.text-bottom {
  position: absolute;
  bottom: 2em;
  top: auto;
}
.text-left {
  position: absolute;
  left: 3em;
}
.text-right {
  position: absolute;
  right: 3em;
  left: auto;
}
.text-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
}

button.no-decoration {
  border: none;
  background: none;
}

button {
  cursor: pointer;
}

#app {
  font-family: Animeace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  transform-origin: 0 0;
  width: 1920px;
  height: 1080px;
  top: 50%;
  left: 50%;
}
</style>
