import { Howl } from 'howler'

export default {
  install(Vue) {
    const sounds = {
      click: new Howl({
        src: ['./sounds/click.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      error: new Howl({
        src: ['./sounds/error.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      validate: new Howl({
        src: ['./sounds/validate.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      too_bad: new Howl({
        src: ['./sounds/too_bad.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      add: new Howl({
        src: ['./sounds/add.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      remove: new Howl({
        src: ['./sounds/remove.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      touch: new Howl({
        src: ['./sounds/touch.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      ploup: new Howl({
        src: ['./sounds/ploup.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      success: new Howl({
        src: ['./sounds/success.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      ok: new Howl({
        src: ['./sounds/ok.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      tick: new Howl({
        src: ['./sounds/tick.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      }),
      forbidden: new Howl({
        src: ['./sounds/forbidden.wav'],
        autoplay: false,
        loop: false,
        volume: 0.5
      })
    }

    Vue.prototype.$sound = function (soundName) {
      if (sounds[soundName]) {
        sounds[soundName].play()
      }
    }
  }
}
