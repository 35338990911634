import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/combat',
    name: 'Combat',
    component: () => import(/* webpackChunkName: "combat" */ '../views/combat/VueCombat.vue')
  },
  {
    path: '/idee',
    name: 'Idee',
    component: () => import(/* webpackChunkName: "idee" */ '../views/idee/VueIdee.vue')
  },
  {
    path: '/pouvoirs',
    name: 'Pouvoirs',
    component: () => import(/* webpackChunkName: "pouvoirs" */ '../views/pouvoirs/VuePouvoirs.vue')
  },
  {
    path: '/pouvoirs/de-possel-h458f',
    alias: '/pouvoirs/$$de-possel',
    name: 'DePossel',
    component: () => import(/* webpackChunkName: "pouvoir_de-possel" */ '../views/pouvoirs/de-possel/DePossel.vue')
  },
  {
    path: '/pouvoirs/babbage-lovelace-g730p',
    alias: '/pouvoirs/$$babbage-lovelace',
    name: 'BabbageLovelace',
    component: () => import(/* webpackChunkName: "pouvoir_babbage_lovelace" */ '../views/pouvoirs/babbage_lovelace/BabbageLovelace.vue')
  },
  {
    path: '/pouvoirs/carson-j028z',
    alias: '/pouvoirs/$$carson',
    name: 'Carson',
    component: () => import(/* webpackChunkName: "pouvoir_carson" */ '../views/pouvoirs/carson/Carson.vue')
  },
  {
    path: '/pouvoirs/knight-b367q',
    alias: '/pouvoirs/$$knight',
    name: 'Knight',
    component: () => import(/* webpackChunkName: "pouvoir_knight" */ '../views/pouvoirs/knight/Knight.vue')
  },
  {
    path: '/pouvoirs/lavoisier-m409d',
    alias: '/pouvoirs/$$lavoisier',
    name: 'Lavoisier',
    component: () => import(/* webpackChunkName: "pouvoir_lavoisier" */ '../views/pouvoirs/lavoisier/Lavoisier.vue')
  },
  {
    path: '/pouvoirs/appert-v785n',
    alias: '/pouvoirs/$$appert',
    name: 'Appert',
    component: () => import(/* webpackChunkName: "pouvoir_appert" */ '../views/pouvoirs/appert/Appert.vue')
  },
  {
    path: '/pouvoirs/vaucanson-k550w',
    alias: '/pouvoirs/$$vaucanson',
    name: 'Vaucanson',
    component: () => import(/* webpackChunkName: "pouvoir_vaucanson" */ '../views/pouvoirs/vaucanson/Vaucanson.vue')
  },
  {
    path: '*',
    component: Home
  }
]

const router = new VueRouter({
  routes
})

export default router
