import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    zoom: 1
  },

  mutations: {
    SET_ZOOM(state, value) {
      state.zoom = value
    }
  },

  getters: {
    zoom: (state) => {
      return state.zoom
    }
  },

  actions: {
  },

  modules: {
  }
})
