<template lang="pug">
  .menu
    img.p-absolute.w-100(src="@/assets/menu/fond.jpg" srcset="@/assets/menu/fond@2x.jpg 2x")
    svg.p-absolute(width="100%" height="100%" viewBox="0 0 1920 1080" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/")
      g#boutons
        g#bouton_combat(transform="matrix(2.08476,0,0,0.889751,-91.5686,4.09025)" @click="navigate('Combat')")
          path(d="M58.425,22.887L378.876,23.45L289.813,1181.74L58.425,1181.18L58.425,22.887Z" style="fill-opacity:0;")
        g#bouton_pouvoir(transform="matrix(1.00626,0,0,0.9,-6.00786,0)" @click="navigate('Pouvoirs')")
          path(d="M730.132,27.207L1190.25,27.207L1376.79,1172.86L542.012,1172.86L730.132,27.207Z" style="fill-opacity:0;")
        g#bouton_idee(transform="matrix(-2.08476,-2.29779e-16,-1.2107e-16,0.889751,2012.54,4.12202)" @click="navigate('Idee')")
          path(d="M58.425,22.887L378.876,23.45L289.813,1181.74L58.425,1181.18L58.425,22.887Z" style="fill-opacity:0;")
    button.p-absolute.text-combat(@click="navigate('Combat')" v-html="$t('menu.combat')")
    button.p-absolute.text-pouvoir(@click="navigate('Pouvoirs')" v-html="$t('menu.pouvoirs')")
    button.p-absolute.text-idee(@click="navigate('Idee')" v-html="$t('menu.idée')")
    .p-absolute.text-block.text-titre(v-html="$t('menu.titre')")
</template>

<script>
export default {
  name: 'Menu',
  methods: {
    navigate(name) {
      this.$sound('click')
      this.$router.push({ name })
    }
  }
}
</script>

<style lang="less">
.text-titre {
  top: 2em;
  left: 50%;
  width: 30em;
  transform: translateX(-50%);
}
.text-combat, .text-pouvoir, .text-idee {
  height: 4.5em;
  width: 12em;
  bottom: 2em;
  top: auto;
}
.text-combat {
  left: 3em;
}
.text-pouvoir {
  left: 50%;
  width: 15em;
  transform: translateX(-50%);
}
.text-idee {
  right: 3em;
  left: auto;
}
.menu > svg > g#boutons > g {
  cursor: pointer;
}
</style>
